import React, { useRef, useEffect } from 'react';
import { LocomotiveScrollProvider } from 'react-locomotive-scroll';
import Seo from '../components/utils/Seo';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import Cursor from '../components/utils/Cursor';
import Nav from '../components/modules/Nav';
import Loader from '../components/modules/Loader';

function HomePageTemplate({ transitionStatus }: any) {
	const containerRef = useRef(null);

	useEffect(() => {
		document.body.classList.remove('bg-home');
	}, []);

	return (
		<>
			<Seo title="Media" />
			<Header />
			<LocomotiveScrollProvider
				options={
					{
						smooth: true,
						direction: 'horizontal',
						gestureDirection: 'both',
						tablet: {
							direction: 'vertical',
						}
					}
				}
				watch={
					[]
				}
				containerRef={containerRef}
			>
				<Cursor />
				<Nav />
				<main data-scroll-container ref={containerRef}>
					<h1>Media page</h1>
				</main>
			</LocomotiveScrollProvider>
			<Footer />
			<Loader transitionStatus={transitionStatus} />
		</>
	);
}

export default HomePageTemplate;
